import React, { useState } from 'react'
import styles from '../../styles/Home.module.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useWindowSize } from '@/hooks/WindowSize'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const CommonDemo = ({ demo2 }) => {
  const { width } = useWindowSize()
  const backgroundImg = 'https://cdn.webhr.co/Website/images/components/demo.png'

  const router = useRouter()
  const [email, setEmail] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    router.push({
      pathname: '/demo',
      query: {
        em: email,
      },
    })
  }

  const onChange = (e) => {
    setEmail(e.target.value)
  }

  return (
    <>
      {demo2 ? (
        <>
          <div className={styles.demoCenterContainer} style={{ backgroundImage: `url('${backgroundImg}')` }}>
            <h2 style={{ fontSize: 45, fontWeight: '500' }}>
              Schedule <span className={styles.demoMid}>Free Demo </span>For WebHR
            </h2>
            <p>
              Our innovative platform streamlines HR processes, increases efficiency, and improves overall organizational
              <br /> performance. Try WebHR today and see the difference it can make for your organization!
            </p>
            <Link href={'/demo'}>
              <Button className={styles.commonButton}>
                <p>Get started now</p>
              </Button>
            </Link>
          </div>
        </>
      ) : (
        <Container className={styles.centerContainer} id="TestimonialsSec">
          <Row>
            <Col>
              {width > 775 ? (
                <h2>
                  Schedule Free Demo
                  <br /> For WebHR
                </h2>
              ) : (
                <h3 style={{ fontWeight: 'bold', fontFamily: 'AzoSansBold' }}>
                  Schedule Free Demo
                  <br /> For WebHR
                </h3>
              )}
              {width > 775 && (
                <p style={{ fontSize: 14, marginBlock: 20 }}>
                  Our innovative platform streamlines HR processes, increases efficiency, <br />
                  and improves overall organizational performance. Try WebHR today and see <br />
                  the difference it can make for your organization!
                </p>
              )}

              <Form onSubmit={onSubmit} className={styles.commonDemoForm}>
                <Form.Group style={{ marginRight: 10 }}>
                  <Form.Control name="ea" type="text" value={email.ea} placeholder="Email Address" className={styles.commonDemoEmail} onChange={onChange} />
                </Form.Group>
                <Button type="submit" onClick={onSubmit} className={styles.purpleButton} style={{ paddingBlock: 10, fontSize: width > 775 ? 14 : 10, paddingInline: 20, border: 'none' }}>
                  Schedule Now!
                </Button>
              </Form>
            </Col>
            {width > 1330 && (
              <Col>
                <LazyLoadImage src="https://cdn.webhr.co/Website/images/modules_illustration/Demo_Illustration.svg" width={'100%'} />
              </Col>
            )}
          </Row>
        </Container>
      )}
    </>
  )
}

export default CommonDemo
